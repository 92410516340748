import React, { useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { FaGithub, FaLinkedin, FaFileAlt, FaEnvelope } from 'react-icons/fa';
import { WiDaySunny } from "react-icons/wi";
import { MdNightlight } from "react-icons/md";
import '../styles/NavScroll.css';



function NavScroll({ toggleDarkMode, isDarkMode }) {
    const toggleButtonRef = useRef(null); // Create a ref for the button

    const handleButtonRef = () => {
        toggleButtonRef.current.blur(); // Ensure the button loses focus after click.
    };

    return(
        <div>
            <Navbar bg={isDarkMode ? "dark" : "light"} variant={isDarkMode ? "dark" : "light"} expand="lg">
                <Container>
                    <Navbar.Brand as={Link} to="/">Home</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                        <Nav.Link as={Link} to="/about">About</Nav.Link>
                        <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
                        <Nav.Link as={Link} to="/skills">Skills</Nav.Link>
                        <Nav.Link href="#blog">Blogs</Nav.Link>
                        <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                        </Nav>
                        {/* Scrollable Social Icons */}
                        <div className="scrollable-icons">
                            <Nav className="flex-row">
                                <Nav.Link href="https://github.com/anamolkhadka" target="_blank">
                                    <FaGithub size={30} />
                                </Nav.Link>
                                <Nav.Link href="https://www.linkedin.com/in/anamolkhadka/" target="_blank">
                                    <FaLinkedin size={30} />
                                </Nav.Link>
                                <Nav.Link href="/Resume.pdf" target="_blank">
                                    <FaFileAlt size={30} />
                                </Nav.Link>
                                <Nav.Link href="mailto:khadkaanamol8@gmail.com">
                                    <FaEnvelope size={30} />
                                </Nav.Link>
                            </Nav>
                        </div>
                        {/* Toggle button for day/night mode */}
                        <Button
                            className={`nav-toggle-btn ${isDarkMode ? 'dark-mode-btn' : 'light-mode-btn'}`}
                            onClick={() => { toggleDarkMode(); handleButtonRef(); }}
                            ref={toggleButtonRef} // Attach the ref to the button
                        >
                            {isDarkMode ? <WiDaySunny size={30} /> : <MdNightlight size={30} />}
                        </Button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}
export default NavScroll;
