// About Information
export const about = {
    info: `MS in Computer Science | Software Developer | Graduate Research Assistant | Ex-Accountant | Seeking SWE Internships/Full-time | University of Texas at Arlington`
};

// Interest Information
export const interest = {
    interest: `Software engineering | Full-Stack Software development | Backend Software development | Enterprise business applications | Artificial Intelligence`
};

// Education Information
export const education = [
    {
        school: "The University of Texas at Arlington",
        degree: "Master of Science in Computer Science | Specializations: Software Engineering and Intelligent Systems",
        duration: "Jan 2024 - Present"
    },
    {
        school: "The University of Texas at Arlington",
        degree: "Honors Bachelor of Science, Accounting and Computer Science",
        duration: "August 2019 - December 2023"
    }
];

// Experience Information
export const experience = [
    {
        company: "UT Arlington Libraries",
        role: "Graduate Research Assistant - Software Developer",
        duration: "July 2024 - Present"
    },
    {
        company: "Howard, LLP (Merged to Bonadio Group)",
        role: "Audit Associate",
        duration: "Jan 2023 - Aug 2023"
    },
    {
        company: "CLA (Clifton Larson Allen)",
        role: "Tax Accountant",
        duration: "Jan 2022 - Apr 2022"
    },
    {
        company: "May Mobility",
        role: "Accountant",
        duration: "May 2021 - Dec 2021"
    },
];

// Leadership and College Organizations
export const leadership = [
    {
        organization: "Open Education Student Advocacy Group (OESAG)",
        role: "Founder and President",
        duration: "July 2024 - Present"
    },
    {
        organization: "Beta Gamma Sigma",
        role: "Member",
        duration: "Feb 2022 - Present"
    },
    {
        organization: "Honors College",
        role: "Member",
        duration: "August 2019 - Dec 2023"
    },
    {
        organization: "Accounting Society",
        role: "Vice President | Member",
        duration: "Jan 2020 - Dec 2021"
    },
]

export const technicalSkills = [
    {
        title: "Programming Languages",
        description: "JavaScript, Java, C, Python, Kotlin, SQL, TypeScript."
    },
    {
        title: "Frameworks and Libraries",
        description: "React JS, Bootstrap, jQuery, Node.js, Express.js, Axios, Spring Boot, Spring MVC, JUnit, tkinter, numpy, scipy."
    },
    {
        title: "Database",
        description: "PostgreSQL, SQLite, MySQL, NoSQL, Firebase."
    },
    {
        title: "Methodologies",
        description: "Agile, Waterfall, Spiral Process, Unified Process."
    },
    {
        title: "Software Design Patterns",
        description: "Visitor, Creator, Singleton, State, Expert, Composite, GRASP, Other GoF patterns."
    },
    {
        title: "Web Technologies",
        description: "HTML 5, EJS, CSS, XML, JSON, REST, Github Pages, WordPress, Wix, Canva."
    },
    {
        title: "Tools",
        description: "Git, GitHub, npm, Postman, VS Code, Wireshark, Anaconda."
    },

]

export const projects = [
    {
        title: "StockSync: Real-Time Inventory Management System",
        techStack: "React, Node.js, PostgreSQL, Express.js, JavaScript",
        imageSrc: "/images/inventory.png",
        description: `StockSync is a comprehensive full-stack project designed to manage perpetual inventory systems in accounting. 
        The application provides real-time inventory valuation using the average inventory pricing method, crucial for calculating the cost of sales 
        for financial reporting. Users can manage their profiles, customer and supplier information, inventory items, transactions, and more.`,
        link: "https://github.com/anamolkhadka/StockSync_Public"
    },
    {
        title: "Student Information Exchange (Android application)",
        imageSrc: "/images/SIE.png",
        techStack: "Kotlin, XML, Firebase, NoSQL, Android Studio",
        description: `Student Information Exchange is an Android application that helps the university students to exchange information. 
        Students can use this app to form an organization/clubs , buy/sell/trade second hand items , request for tutoring services , 
        direct message to the individuals or in the Group , search for any Upcoming events , and much more.`,
        link: "https://github.com/anamolkhadka/Student_Information_Exchange_project"
    },
    {
        title: "Car Rental Service",
        imageSrc: "/images/carRental.png",
        techStack: "Python, tkinter, SQL, SQLite",
        description: `This is a car rental database management application created for businesses providing car rental services. 
        This application is created using Python for front-end/back-end and SQLite for database management.`,
        link: "https://github.com/anamolkhadka/carRental"
    },
    {
        title: "My Journal (web application)",
        imageSrc: "/images/myjournal.png",
        techStack: "React, JavaScript, Firebase, NodeJS, HTML, CSS, Boostrap 5",
        description: `My Journal is a react based web application that is integrated with the real time database management system with firestone. 
        This web app provides a platform to any users to create an online Journal for record keeping and expressing their daily activities, events and emotions they want to convey.`,
        link: "https://github.com/anamolkhadka/myjournal"
    },
]