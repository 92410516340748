import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import ParticlesComponent from "./Particles";
import { technicalSkills } from "../data/data";
import "../styles/skills.css"


function Skills({isDarkMode}) {

    return(
        <div className="skills-container">
             {/* Particles as the background */}
             <ParticlesComponent isDarkMode={isDarkMode} id="tsparticles" />

            {/* Skills Card with Title */}
            <div className={`skills-card ${isDarkMode ? 'dark' : 'light'}`}>
                <h2>Technical Skills</h2>
                <Accordion defaultActiveKey="0">
                    {technicalSkills.map((skill, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Button className={`accordion-button ${isDarkMode ? 'dark' : 'light'}`}>
                                <strong>{skill.title}</strong>
                            </Accordion.Button>
                            <Accordion.Body className={`accordion-body ${isDarkMode ? 'dark' : 'light'}`}>
                                {skill.description}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    )
}
export default Skills;