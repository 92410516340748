import React from "react";
import ParticlesComponent from "./Particles"; // Import your ParticlesComponent
import "../styles/home.css"; // Import any custom styles for the Home component

function Home({isDarkMode}) {
    return(
        <div className="home-container">
            {/* Particles Background */}
            <ParticlesComponent isDarkMode={isDarkMode} id="tsparticles" />

            {/* Content */}
            <div className="centered-content" style={{ color: isDarkMode ? '#ffffff' : '#333333'}}>
                <h1>Anamol Khadka</h1>
                <p>"Bringing ideas to life, one line of code at a time. Let's craft something inspiring and innovative together."</p>
            </div>
        </div>
    );
    
}
export default Home;