import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim"; // loads tsparticles-slim
//import { loadFull } from "tsparticles"; // loads tsparticles
import { useCallback, useMemo } from "react";

// tsParticles Repository: https://github.com/matteobruni/tsparticles
// tsParticles Website: https://particles.js.org/
const ParticlesComponent = ({ isDarkMode, id }) => {

    // Get screen width and adjust particle count accordingly
    const calculateParticleCount = () => {
        const screenWidth = window.innerWidth;
        
        if (screenWidth < 576) {
        return 30; // Fewer particles for very small screens
        } else if (screenWidth < 768) {
        return 50; // Slightly more particles for small screens
        } else {
        return 100; // Default number for larger screens
        }
    };

    // using useMemo is not mandatory, but it's recommended since this value can be memoized if static
    const options = useMemo(() => {
        // using an empty options object will load the default options, which are static particles with no background and 3px radius, opacity 100%, white color
        // all options can be found here: https://particles.js.org/docs/interfaces/Options_Interfaces_IOptions.IOptions.html
        return {
            background: {
                color: isDarkMode ? "#000000" : "#ffffff",
            },
            fullScreen: {
                enable: true, // enabling this will make the canvas fill the entire screen, it's enabled by default
                zIndex: -1, // this is the z-index value used when the fullScreen is enabled, it's 0 by default
            },
            interactivity: {
                events: {
                    onClick: { enable: true, mode: "push" }, // Click event to add particles
                    onHover: { enable: true, mode: "repulse" }, // Hover event to repel particles
                },
                modes: {
                    push: { quantity: 10 }, // Adds 10 particles on click
                    repulse: { distance: 100 }, // Repulse distance on hover
                },
            },
            particles: {
                number: {
                    value: calculateParticleCount(), // Number of particles that will be rendered at the start
                },
                links: {
                    enable: true, // enabling this will make particles linked together
                    distance: 150, // maximum distance for linking the particles
                    color: isDarkMode ? "#ffffff" : "#000000", // Dynamic particle link color
                },
                move: {
                    enable: true, // enabling this will make particles move in the canvas
                    speed: { min: 1, max: 5 }, // using a range in speed value will make particles move in a random speed between min/max values, each particles have its own value, it won't change in time by default
                },
                opacity: {
                    value: { min: 0.3, max: 0.7 }, // using a different opacity, to have some semitransparent effects
                },
                size: {
                    value: { min: 1, max: 3 }, // let's randomize the particles size a bit
                },
            },
        };
    }, [isDarkMode]);

    // useCallback is not mandatory, but it's recommended since this callback can be memoized if static
    const particlesInit = useCallback((engine) => {
        loadSlim(engine);
        // loadFull(engine); // for this sample the slim version is enough, choose whatever you prefer, slim is smaller in size but doesn't have all the plugins and the mouse trail feature
    }, []);

    // setting an id can be useful for identifying the right particles component, this is useful for multiple instances or reusable components
    return <Particles id={id} init={particlesInit} options={options} />;
};

export default ParticlesComponent;