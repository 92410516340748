import React from "react";
import ParticlesComponent from "./Particles";
import emailjs from "emailjs-com";
import { FaGithub, FaLinkedin, FaEnvelope, FaInstagram } from "react-icons/fa";
import "../styles/contact.css"; // Assume the CSS file is created separately

function Contact({ isDarkMode }) {
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        const formData = new FormData(e.target); // Create new FormData object
        const templateParams = {
            user_name: formData.get("user_name"),
            user_email: formData.get("user_email"),
            message: formData.get("message"),
        };

        // Send email via EmailJS
        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAILJS_USER_ID
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Message sent successfully!');
            // Clear the form after successful submission
            e.target.reset();
        })
        .catch((error) => {
            console.log('FAILED...', error);
            alert('Failed to send the message. Please try again.');
            e.target.reset();
        });
    };

  return (
    <div className="contact-section">
        {/* Particles Background */}
        <ParticlesComponent isDarkMode={isDarkMode} id="tsparticles" />

        {/* Contact Page Content */}
        <div className={`contact-container ${isDarkMode ? 'dark' : 'light'}`}>
            <h2 className={`contact-title ${isDarkMode ? 'dark' : 'light'}`}>
                Let's Connect
            </h2>
            <p className={`contact-intro ${isDarkMode ? 'dark' : 'light'}`}>
                I'm open to opportunities, collaborations, or even a friendly chat. Feel free to reach out!
            </p>

            {/* Contact Form */}
            <form onSubmit={handleSubmit} className={`contact-form ${isDarkMode ? 'dark' : 'light'}`}>
                <input
                    type="text"
                    name="user_name"
                    placeholder="Your Name"
                    className="form-input"
                    required
                />
                <input
                    type="email"
                    name="user_email"
                    placeholder="Your Email"
                    className="form-input"
                    required
                />
                <textarea
                    name="message"
                    placeholder="Your Message"
                    className="form-textarea"
                    required
                ></textarea>
                <button type="submit" className={`submit-button ${isDarkMode ? 'dark' : 'light'}`}>
                    Send Message
                </button>
            </form>

            {/* Social Links */}
            <div className="social-links">
                <a href="https://github.com/anamolkhadka" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <FaGithub size={40} />
                </a>
                <a href="https://www.linkedin.com/in/anamolkhadka/" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <FaLinkedin size={40} />
                </a>
                <a href="mailto:khadkaanamol8@gmail.com" className="social-icon">
                    <FaEnvelope size={40} />
                </a>
                <a href="https://www.instagram.com/anamolkhadkaofficial/" target="_blank" rel="noopener noreferrer" className="social-icon">
                    <FaInstagram size={40} />
                </a>
            </div>
        </div>
    </div>
  );
}

export default Contact;
