import React from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ParticlesComponent from "./Particles";
import { projects } from "../data/data";
import "../styles/project.css"

function Projects({isDarkMode}){
    return (
        <div className="projects-section">
            {/* Particles Background */}
            <ParticlesComponent isDarkMode={isDarkMode} id="tsparticles" />
            <h4 className={`projects-title ${isDarkMode ? 'dark' : 'light'}`}>A Glimpse of my projects. Find all of my projects on <a href="https://github.com/anamolkhadka"target="_blank" rel="noopener noreferrer">Github</a></h4>
            <div className="project-container">
                {projects.map((project, index) => (
                    <Card className={`project-card ${isDarkMode ? 'dark' : 'light'}`} key={index}>
                        <Card.Img variant="top" src={project.imageSrc} />
                        <Card.Body>
                            <Card.Title className={`project-title ${isDarkMode ? 'dark' : 'light'}`}>{project.title}</Card.Title>
                            <p className={`project-card-text ${isDarkMode ? 'dark' : 'light'}`}>{project.techStack}</p>
                            <Card.Text className={`project-description ${isDarkMode ? 'dark' : 'light'}`}>{project.description}</Card.Text>
                            <Button href={project.link} target="_blank" rel="noopener noreferrer" variant="outline-primary">
                                More information
                            </Button>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </div>
    );
}
export default Projects;
